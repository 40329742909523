import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import AnimationRevealPage from "../components/helpers/AnimationRevealPage.jsx"
import Header from "../components/Header.jsx"
import Contact from "../components/Contact.jsx"
import Footer from "../components/Footer.jsx"
import UseCase from "../components/UseCase.jsx"
import SEO from "../components/misc/SEO.jsx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const Container = tw.div`relative`
const ContentWithPaddingXl = tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`
const Heading = tw.div`text-4xl sm:text-5xl font-black tracking-wide text-left text-gray-900 my-10`

const HeadingRow = tw.div`flex flex-col`
const GatsbyImageFloat = tw(GatsbyImage)`w-full`

const Text = styled.div`
  ${tw`text-base  text-gray-800`}
  p {
    ${tw`my-1 leading-loose`}
  }
  h1 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8 border-b-2`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  h4 {
    ${tw`text-base font-bold mt-3 `}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`

export default ({ data }) => {
  const post = data.markdownRemark
  const title = post.frontmatter.title
  const service = post.frontmatter.service
  const image = post.frontmatter.thumbnail.childrenImageSharp[0]

  return (
    <AnimationRevealPage disabled>
      <SEO title={title} />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{title}</Heading>
            <GatsbyImageFloat image={getImage(image)} alt="test" />
          </HeadingRow>
          <Text>
            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
            />
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <UseCase service={service} />
      <Contact />
      <Footer />
    </AnimationRevealPage>
  )
}

export const pageQuery = graphql`
  query UseCaseBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        service
        title
        description
        thumbnail {
          childrenImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
              width: 1200
              height: 400
            )
          }
        }
      }
    }
  }
`
